import React from "react"

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
            {/* <h1>ACADEMIA</h1> */}
            <h1>MSITPARK</h1>
            {/* <span>ONLINE EDUCATION & LEARNING</span> */}
            <span>SOFTWARE TECHNOLOGIES</span>
          </div>

          <div className='social'>
            {/* <i className='fab fa-facebook-f icon'></i> */}
            <a href="https://www.linkedin.com/in/msitpark-228957282?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" className="fab fa-linkedin icon"></a>
            <a href="https://www.msitpark.com" className='fab fa-instagram icon'></a>
            <a href="https://www.msitpark.com" className='fab fa-twitter icon'/>
            <a href="https://www.msitpark.com" className='fab fa-youtube icon'/>
            
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
