import React from "react"
import Heading from "../../common/heading/Heading"
import "./Hero.css"

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='row'>
            <Heading subtitle='WELCOME TO MSITPARK' title='Best Software Solution Expertise' />
            <p>Empowering businesses with innovative software solutions, turning challenges into opportunities and ideas into reality .</p>
            <div className='button'>
              <button className='primary-btn'>
              Discover Our Expertise 
              {/* <i className='fa fa-long-arrow-alt-right'></i> */}
              </button>
              <button>
              Get Started Today 
              {/* <i className='fa fa-long-arrow-alt-right'></i> */}
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className='margin'></div>
    </>
  )
}

export default Hero
